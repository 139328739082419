'use strict';

const { isNumber, isString } = require('@hints/utils/types');
const { TemplateConfiguration } = require('./template-utils');
const currencyService = require('./formatting/currency-service');

const config = new TemplateConfiguration({
    forgottenPassword: {
        type: 'mail',
        for: 'system',
        variables: {
            url: { type: 'url', label: 'template-variables.url', required: true },
        },
    },
    newPasswordEmail: {
        type: 'mail',
        for: 'system',
        variables: {
            url: { type: 'url', label: 'template-variables.url', required: true },
        },
    },
    askNewPasswordEmail: {
        type: 'mail',
        for: 'system',
        variables: {
            url: { type: 'url', label: 'template-variables.url', required: true },
        },
    },
    invitationMail: {
        type: 'mail',
        for: 'system',
        variables: {
            userName: { type: 'string', label: 'template-variables.user-name' },
            companyName: { type: 'string', label: 'template-variables.company-name' },
            url: { type: 'url', label: 'template-variables.url', required: true },
        }
    },
    invitationRefused: {
        type: 'mail',
        for: 'system',
        variables: {
            userHostName: { type: 'string', label: 'template-variables.user-host-name' },
            userName: { type: 'string', label: 'template-variables.user-name' },
            companyName: { type: 'string', label: 'template-variables.customer-name' },
        }
    },
    invitationAccepted: {
        type: 'mail',
        for: 'system',
        variables: {
            userHostName: { type: 'string', label: 'template-variables.user-host-name' },
            userName: { type: 'string', label: 'template-variables.user-name' },
            companyName: { type: 'string', label: 'template-variables.customer-name' },
        }
    },
    confirmationRegisterMail: {
        type: 'mail',
        for: 'system',
        variables: {
            url: { type: 'url', label: 'template-variables.url', required: true },
        },
    },
    sendQuote: {
        type: 'mail',
        for: 'company',
        constraints: ['isVisible', 'accessToken'],
        variables: {
            companyName: { type: 'string', label: 'template-variables.company-name' },
            contactFirstName: { type: 'string', label: 'template-variables.contact-first-name', optional: true },
            contactLastName: { type: 'string', label: 'template-variables.contact-last-name', optional: true },
            contactFullName: { type: 'string', label: 'template-variables.contact-full-name', optional: true },
            customerName: { type: 'string', label: 'template-variables.customer-name', optional: true },
            ref: { type: 'string', label: 'template-variables.billing-ref' },
            amount: { type: 'currency', label: 'template-variables.quote-amount' },
            url: { type: 'url', label: 'template-variables.billing-url' },
        }
    },
    sendQuoteAttachment: {
        type: 'mail',
        for: 'company',
        variables: {
            companyName: { type: 'string', label: 'template-variables.company-name' },
            contactFirstName: { type: 'string', label: 'template-variables.contact-first-name', optional: true },
            contactLastName: { type: 'string', label: 'template-variables.contact-last-name', optional: true },
            contactFullName: { type: 'string', label: 'template-variables.contact-full-name', optional: true },
            customerName: { type: 'string', label: 'template-variables.customer-name', optional: true },
            ref: { type: 'string', label: 'template-variables.billing-ref' },
            amount: { type: 'currency', label: 'template-variables.quote-amount' },
        }
    },
    sendInvoice: {
        type: 'mail',
        for: 'company',
        constraints: ['isVisible', 'accessToken'],
        variables: {
            companyName: { type: 'string', label: 'template-variables.company-name' },
            contactFirstName: { type: 'string', label: 'template-variables.contact-first-name', optional: true },
            contactLastName: { type: 'string', label: 'template-variables.contact-last-name', optional: true },
            contactFullName: { type: 'string', label: 'template-variables.contact-full-name', optional: true },
            customerName: { type: 'string', label: 'template-variables.customer-name', optional: true },
            ref: { type: 'string', label: 'template-variables.billing-ref' },
            amount: { type: 'currency', label: 'template-variables.invoice-amount' },
            url: { type: 'url', label: 'template-variables.billing-url' },
        }
    },
    sendInvoiceAttachment: {
        type: 'mail',
        for: 'company',
        variables: {
            companyName: { type: 'string', label: 'template-variables.company-name' },
            contactFirstName: { type: 'string', label: 'template-variables.contact-first-name', optional: true },
            contactLastName: { type: 'string', label: 'template-variables.contact-last-name', optional: true },
            contactFullName: { type: 'string', label: 'template-variables.contact-full-name', optional: true },
            customerName: { type: 'string', label: 'template-variables.customer-name', optional: true },
            ref: { type: 'string', label: 'template-variables.billing-ref' },
            amount: { type: 'currency', label: 'template-variables.invoice-amount' },
        }
    },
    sendCommercialOffer: {
        type: 'mail',
        for: 'company',
        variables: {
            companyName: { type: 'string', label: 'template-variables.company-name' },
            quoteList: { type: 'string', label: 'template-variables.quote-list' },
            url: { type: 'url', label: 'template-variables.commercial-offer-url' },
        }
    },
    sendExportGeneration: {
        type: 'mail',
        for: 'system',
        variables: {
            companyName: { type: 'string', label: 'template-variables.company-name', required: true },
            ownerName: { type: 'string', label: 'template-variables.owner-name', required: true },
            userName: { type: 'string', label: 'template-variables.user-name', required: true },
            startDate: { type: 'string', label: 'template-variables.start-date', required: true },
            endDate: { type: 'string', label: 'template-variables.end-date', required: true },
            url: { type: 'url', label: 'template-variables.url', required: true },
        }
    },
    sendExportAvailable: {
        type: 'mail',
        for: 'system',
        variables: {
            companyName: { type: 'string', label: 'template-variables.company-name', required: true },
            userName: { type: 'string', label: 'template-variables.user-name', required: true },
            startDate: { type: 'string', label: 'template-variables.start-date', required: true },
            endDate: { type: 'string', label: 'template-variables.end-date', required: true },
            url: { type: 'url', label: 'template-variables.url', required: true },
        }
    },
    sendOnQuoteSigned: {
        type: 'mail',
        for: 'system',
        variables: {
            companyName: { type: 'string', label: 'template-variables.company-name', required: true },
            userName: { type: 'string', label: 'template-variables.user-name', required: true },
            url: { type: 'url', label: 'template-variables.url', required: true },
            ref: { type: 'string', label: 'template-variables.billing-ref' },
            amount: { type: 'currency', label: 'template-variables.quote-amount' },
            contactFullName: { type: 'string', label: 'template-variables.contact-full-name', optional: true },
        }
    },
    sendOnQuoteRefused: {
        type: 'mail',
        for: 'system',
        variables: {
            companyName: { type: 'string', label: 'template-variables.company-name', required: true },
            userName: { type: 'string', label: 'template-variables.user-name', required: true },
            url: { type: 'url', label: 'template-variables.url', required: true },
            ref: { type: 'string', label: 'template-variables.billing-ref' },
            amount: { type: 'currency', label: 'template-variables.quote-amount' },
            contactFullName: { type: 'string', label: 'template-variables.contact-full-name', optional: true },
        }
    },
    reporterReminderMail: {
        type: 'mail',
        for: 'system',
        variables: {
            companyName: { type: 'string', label: 'template-variables.company-name', required: true },
            dates: { type: 'url', label: 'template-variables.dates', required: true }
        }
    },
    generic: {
        type: 'mail',
        for: 'admin',
        variables: {}
    }
}, {
    currency: { validate: isNumber, format: v => currencyService.format(v) },
    url: { validate: isString, format: v => `<a href="${v}">${v}</a>` }
});

module.exports = {
    config
};