import { } from '@angular/animations/browser';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CwtCoreModule } from '@cawita/core-front';
import { CwtZorroModule } from '@cawita/core-front/zorro';
import { SharedUserModule } from '@shared/modules/user';
import { RecaptchaModule } from '@shared/widgets/features/common/recaptcha';
import { environment } from '../../environments/environment';
import { CwtAdminCoreConfig } from '../configs/core-front.config';
import { AppComponent } from './app.component';
import AppRoutes from './app.routes';
import { SharedCommonModule } from '@shared/modules';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';
import { QuillModule } from 'ngx-quill';
import { QuillConfig } from '@shared/config/quill.config';

@NgModule({
  declarations: [
    AppComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CwtCoreModule.forRoot(CwtAdminCoreConfig),
    CwtZorroModule.forRoot(),
    SharedCommonModule.forRoot(),
    SharedUserModule.forRoot(),
    QuillModule.forRoot(QuillConfig),
    RecaptchaModule.forRoot(environment.recaptchaSiteKey),
    RouterModule.forRoot(AppRoutes)
  ]
})
export class AppModule { }
