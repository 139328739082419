import { Inject, Injectable, Injector, NgZone } from '@angular/core';
import { asArray, isset } from '@cawita/core-front';
import { EmailTemplateService } from '@server-shared';
import { TemplateUseCase } from '@shared/models';
import { isPromise } from 'ng-zorro-antd/core/util';
import { Observable, isObservable, lastValueFrom } from 'rxjs';
import { MailAction, MailParams } from '../mailing-types';
import { ConstraintPromptResponse, MAILING_CONFIG, MailConstraintHandler, MailingConfig } from '../providers';
import { MailingService } from './mailing.service';
import { NoopConstraintHandler } from './noop-constraint-handler';
import { isBoolean } from '@hints/utils/types';

export type ConstraintResolveOptions = {
    constraintsFound?: () => void;
    constraintsUnresolved?: () => void;
    constraintsResolved?: () => void;
}

@Injectable({ providedIn: 'root' })
export class MailingConstraintService {
    private _injectionCache: Partial<Record<MailAction, Record<string, MailConstraintHandler>>> = {};

    constructor(
        private injector: Injector,
        private mailing: MailingService,
        @Inject(MAILING_CONFIG) private config: MailingConfig,
        private zone: NgZone
    ) { }

    public async resolveConstraints(action: MailAction, useCase: TemplateUseCase, params: MailParams, options: ConstraintResolveOptions) {
        const constraints = EmailTemplateService.getConstraints(useCase);
        await this._handleConstraints(action, params, constraints, options);
    }

    public async resolveCommonConstraints(action: MailAction, params: MailParams, options: ConstraintResolveOptions) {
        const useCases = asArray(this.mailing.getTemplatesUseCases(action));
        const constraints = EmailTemplateService.getCommonConstraints(useCases);
        await this._handleConstraints(action, params, constraints, options);
    }

    private async _handleConstraints(action: MailAction, params: MailParams, constraints: string[], options: ConstraintResolveOptions) {
        if (!constraints.length) return options.constraintsResolved?.();
        this.zone.run(() => options.constraintsFound?.());
        const constrainstsToResolve = await this._checkConstraints(action, params, constraints);
        if (!constrainstsToResolve.length) return options.constraintsResolved?.();
        const resolved = await this._promptAndResolveConstraints(action, params, constrainstsToResolve);
        if (!resolved) return options.constraintsUnresolved?.();
        return options.constraintsResolved?.();
    }

    private async _checkConstraints(action: MailAction, params: MailParams, constraints: string[]): Promise<string[]> {
        const constraintsToResolve = [];
        for (const constraint of constraints) {
            const handler = this._getConstaintHandler(action, constraint);
            const result = await this._convertToPromise(handler.check(params, { constraint, action }));
            if (!result) constraintsToResolve.push(constraint);
        }
        return constraintsToResolve;
    }

    private async _promptAndResolveConstraints(action: MailAction, params: MailParams, constraints: string[]): Promise<boolean> {
        for (const constraint of constraints) {
            const handler = this._getConstaintHandler(action, constraint);
            const promptResponse = await this._convertToPromise(handler.prompt(params, { constraint, action }));
            const shouldResolve = this._coercePromptResolve(promptResponse);
            if (!shouldResolve) return false;
            const promptData = this._coercePromptData(promptResponse);
            const result = await this._convertToPromise(handler.resolve(params, { constraint, action, promptData }));
            if (!result) return false;
        }
        return true;
    }

    private _coercePromptData<D>(promptResponse: ConstraintPromptResponse<D>): D {
        if (isBoolean(promptResponse)) return undefined;
        return promptResponse.data;
    }

    private _coercePromptResolve(promptResponse: ConstraintPromptResponse): boolean {
        if (isBoolean(promptResponse)) return promptResponse;
        return promptResponse.resolve;
    }

    private _convertToPromise<T>(value: Promise<T> | Observable<T> | T): Promise<T> {
        if (isPromise(value)) return value;
        if (isObservable(value)) return lastValueFrom(value);
        return Promise.resolve(value);
    }

    private _getConstaintHandler(action: MailAction, constraint: string): MailConstraintHandler {
        if (!this._injectionCache[action]?.[constraint]) {
            if (!this._injectionCache[action]) this._injectionCache[action] = {};
            const constraintType = this.config?.adapters?.[action]?.constraints?.[constraint];
            if (!isset(constraintType)) {
                this._injectionCache[action][constraint] = this.injector.get<MailConstraintHandler>(NoopConstraintHandler);
            } else {
                this._injectionCache[action][constraint] = this.injector.get<MailConstraintHandler>(constraintType);
            }
        }

        return this._injectionCache[action][constraint];
    }
}